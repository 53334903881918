import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Head from '../components/head'

const BlogPage = ({ data }) => {
  const allPosts = data.allContentfulBlogPost.edges

  return (
    <Layout title="Articles de Jérôme Dicharry, développeur web Gatsby JS | Next JS à Strasbourg">
      <Head
        title="Blog de Jérôme Dicharry"
        description="Lire les actualités de Jérôme Dicharry à propos du développement web, du SEO, de php, WordPress, React JS, Next JS et Gatsby JS"
      />
      <section className="section">
        <h2 className="mb-2 text-center">Mes derniers articles</h2>
        <h3 className="text-center mb-2">
          Ici je partage avec vous mes expériences de freelance, de développeur,
          d'agence web, de code et de vie !
        </h3>
        <ul className="posts grid-x grid-margin-x align-center align-stretch">
          {allPosts.map((edge) => {
            const image = getImage(edge.node.image)
            return (
              <li key={edge.node.slug} className="cell medium-8 large-6 post">
                <Link to={`/articles/${edge.node.slug}`}>
                  <GatsbyImage
                    image={image}
                    className="post-image mb-1"
                    alt={edge.node.image.title}
                    loading="lazy"
                    placeholder="tracedSVG"
                    objectFit="cover"
                    objectPosition="center"
                  />
                  <h2>{edge.node.title}</h2>
                  <p>{edge.node.publishedDate}</p>
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          title
          slug
          publishedDate(formatString: "DD MMMM YYYY", locale: "fr")
          image {
            title
            gatsbyImageData(width: 600, height: 300)
          }
        }
      }
    }
  }
`

export default BlogPage
